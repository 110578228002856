import { PATH } from "../constants/paths";

export const ROUTES = [
    {
        path:PATH.home,
        title:"home"
    },
    {
        path:PATH.projects,
        title:"projects"
    },
    {
        path:PATH.aboutme,
        title:"about-me"
    },
    // {
    //     path:PATH.contacts,
    //     title:"contacts"
    // },
]