import React, { useEffect, useState } from 'react';
import dayjs from "dayjs";
import {Box} from "@mui/material";

const target = '2025-07-11T00:00:00+04:00';

function Timer() {
    const [state, setState] = useState(() => {
        const targetDate = dayjs(target);
        const currentDate = dayjs();
        return targetDate.diff(currentDate, 'milliseconds');
    });

    function convertToReadableDate(milliseconds:number) {
        const days = Math.floor(milliseconds / (24 * 60 * 60 * 1000));
        const hours = Math.floor((milliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((milliseconds % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
        return `${days} gün, ${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const targetDate = dayjs(target);
            const currentDate = dayjs();
            const diff = targetDate.diff(currentDate, 'milliseconds');
            if(diff<0){

            }
            setState(diff);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <div className={'timer'}>
            <Box component="span" sx={{color:"#C778DD"}}>#</Box>{convertToReadableDate(state)}
        </div>
    );
}

export default Timer;
